import { navigateTo } from '#app/composables/router';
import { _refreshToken } from '~/utils/userClient';
import { getAccessToken, getRefreshToken, setToken } from '~/utils/tokens';

export default defineNuxtRouteMiddleware(async () => {
  try {
    const token = getAccessToken();
    if (token) {
      return;
    }
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      const config = useRuntimeConfig();
      const data = await _refreshToken(config.public.CLIENT_ID, config.public.CLIENT_SECRET, refreshToken);
      if (data) {
        setToken(data);
        return;
      }
    }
    if (localStorage.getItem('user')) {
      localStorage.removeItem('user');
    }
    return navigateTo({ path: '/' });
  } catch {
    return navigateTo({ path: '/' });
  }
});
